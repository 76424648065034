import React from 'react';
 import './about.css'
import DoctorBio from '../../components/DoctorBio1/DoctorBio';
import DoctorBioSecond from '../../components/DoctorBioSecond/DoctorBioSecond';
import {OVERLAY_COLOR,PARALEX_OVERLAY_COLOR, OVERLAY_COLOR_DEGRE_2, OVERLAY_COLOR_DEGRE_3} from '../../config'


function About() {
  // let myClass
  // navigator.appVersion.indexOf("Chrome/")!== -1?myClass="header-image-about-chrome":myClass="header-image-about"
 
  return (   
    
          <div className="about-who-we-are">
              <div id="about-header">
                <div className="overlay1">
                   
                          <div id="about-title">
                              Proudly Serving the East Cobb Community Since 1980
                          </div>
                          <div id="about-sub-title">
                               We are Dedicated to Offering the Most Modern Spinal Correction & Wellness Care Available in East Cobb & Marietta.
                          </div>
                          <a href="/book">
                   		        <button style={{textTransform:"uppercase",fontSize:18}}>schedule now</button>
				                	</a><br/>
                          </div>
                          </div>

                  <div className="who-we-are-background" >
                     {window.innerWidth>1350?
                     <div style={{textAlign:'center',padding:100,paddingTop:'10%',backgroundColor:OVERLAY_COLOR_DEGRE_3,height:'100%'}}>
                        <h1 className="who-we-are-title">
                           WHO WE ARE
                        </h1>
                        <p className="who-we-are-desc">
                           Cardwell's Team of Professionals is Dedicated to Helping You Achieve Optimal Wellness, by Using Our Knowledge, Expertise, & Training. Whether You Need to be Treated for an Injury, Accident or Just an on-going Problem, Our Team is Here for You.             
                        </p>
                     </div>
                        : 


                        <div 
                        className="footer-font"
                         style={{
                           backgroundColor:OVERLAY_COLOR_DEGRE_2,
                               // fontSize:"2.4vw",
                               textAlign:"center",
                               padding:16,
                               color:"white",
                               width:'100%',
                               
                               // height:'50vw',
                             }}
                        >
                          <div 
                              className="who-we-are-box"
                              style={{
                                 borderStyle:'solid',
                                
                                 borderRadius:10,
                                 borderColor:'white'
                                 }}
                          >
                       
                          <h1 className="who-we-are-title">
                             WHO WE ARE
                          </h1>
                          <span className="who-we-are-desc">
                             Cardwell's Team of Professionals is Dedicated to Helping You Achieve Optimal Wellness, by Using Our Knowledge, Expertise, & Training. Whether You Need to be Treated for an Injury, Accident or Just an on-going Problem, Our Team is Here for You.             
                          </span>
                          </div>
                         </div>
                   }
                 </div>
      
         <DoctorBio 
                  classNameC="background-image-doctorbio"
                  direction="left"
                  name="Dr. Sinan Feidy"
                  desc="Dr. Sinan Feidy's interest in chiropractic began while he was earning his bachelor of science in biology at Florida Southern College, where he graduated in 2007. He shortly after began his chiropractic journey when he enrolled in Life University. He received his doctorate in 2012 and began his professional career as a Chiropractic intern at Cardwell Chiropractic Clinic. Never shying away from hard work, Dr. Feidy earned a masters degree in sports injury management while working full time at the clinic. After only 5 years in total at Cardwell Clinic, Dr. Feidy purchased the clinic and now serves as owner and head doctor."
                  blueBox="“I enjoy outdoor activities such as camping, hiking, and paddle boarding. I also enjoy weight training, basketball, and golf.”"
                  v={true}
               />
           
                 <DoctorBio
                       classNameC="MT"
                       direction="right"
                       name="Richelle Brown"
                       desc="Richelle Brown is our Certified and Licensed Massage Therapist; she has been with us since 2008. Richelle specializes in sports massage but is well educated in deep tissue massage, trigger point therapy, cupping and other areas.
                       Richelle attended Columbus State Community College and received her Associate degree in Sports Management. Richelle received her certification in neuromuscular massage therapy with continuous education in low back pain, neck and shoulders, swe-thai, medical massage practitioner and therapeutic massage from Medix. Richelle has also taken classes at Chattahoochee Tech to continue her education in physical therapy. She is ACE certified. Working with the Doctors at Cardwell Chiropractic has expanded Richelle’s knowledge of the relationship between the musculoskeletal system, the nerve system, and overall health."
                       blueBox="“I enjoy staying active by teaching Zumba classes, playing basketball, and attending the gym regularly.”"
                       v={false}
                      />

          </div>

                );
}

export default About;


