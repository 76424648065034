import React from 'react';
import './review.css'
import Contact from '../Contact/Contact'

function Review() {
   
   return (
      
         <div className="review-container">
            <div className="doctor-review">
                <Contact/>
            </div>
         </div>

  );
}

export default Review;
