import React from 'react';
import './treatments.css'
import LazyLoad from 'react-lazyload';

import Navbar from '../../components/navbar/Navbar'
import Card from '../../components/treatments/Card';
import image1 from '../../imgs/chiropractic_adjustment.jpg'
import image2 from '../../imgs/Physical_Therapy.jpg'
import image3 from '../../imgs/Digital_X_Rays.jpg'
import image4 from '../../imgs/foot_orthotics.jpg'
import image5 from '../../imgs/cervical-lumbar-decompression .jpg'
import image6 from '../../imgs/masssage_therapy.jpg'
import image7 from '../../imgs/Family-Welness-East-Cobb.jpg'
import image8 from '../../imgs/Cervical-Decrompression-East-Cobb.jpg'


import { OVERLAY_COLOR, OVERLAY_COLOR_DEGRE_1, OVERLAY_COLOR_DEGRE_2 } from '../../config'

function Treatments() {

   return (
      <div>
         <span className="anchor" id="section1"></span>
         <div className="section"></div>
         <Navbar />
         <div className="treatments-header">
            <div className="about-heaer-text"
               style={{ backgroundColor: OVERLAY_COLOR_DEGRE_2, height: '100%' }}>
               <div style={{

                  // height:'100%',
                  width: '100%'
               }}
               >

                  <div style={{ margin: 'auto', width: '70%' }}>
                     <div className="treatmens-header-title"
                        style={{ paddingTop: '25%', textAlign: 'center' }}
                     >
                        The Most Modern Digital X-Ray Technology in East Cobb
                     </div>
                  </div>
                  <div className="treatmens-header-desc"
                     style={{ textAlign: 'center', color: '#FFFFFF', fontSize: 18, lineHeight: 1.5, margin: '20px auto 30px', maxWidth: 640 }}>
                     We Use the Most Modern Digital X-Ray Technology Available in East Cobb in Order to Diagnose & Treat Each Patient with Pin Point Precision
                  </div>
                  <div style={{ textAlign: 'center', color: '#FFFFFF', fontSize: 14, lineHeight: 1.5, margin: '20px auto 30px', maxWidth: 640 }}>
                     <a href="/book">
                        <button
                           style={{ fontSize: 18, textTransform: 'uppercase' }}
                        >schedule NOW</button>
                     </a>
                  </div>
               </div>
            </div>


         </div>
         {window.innerWidth > 900 ?
            <div style={{
               backgroundColor: 'black',
               textAlign: "center",
               padding: "10% 10% 2% 10%",
            }}
            >
               <LazyLoad offset={-100}>
                  <div
                     className="treatment-box"
                     style={{
                        padding: '36px 86px 86px 86px',
                        width: '99%',
                        margin: 'auto',
                        backgroundColor: '#416FAB',
                        color: "white",
                        fontSize: "2.3vw"
                     }}
                  >


                     <h2 className="what-do">What We Do</h2>
                     <span className="footer-font">
                        Cardwell Chiropractic treats the body, mind, and spirit. We believe in treating each patient with a comprehensive strategy involving physical adjustments, education, nutritional support, and wellness planning. Cardwell Chiropractic has freed many Georgia residents from pain and debilitating medication. Our balance of holistic treatment and sound medical knowledge ensure that you are in good hands.
                     </span>
                  </div>
               </LazyLoad>
            </div>
            :
            <div
               className="footer-font"
               style={{
                  backgroundColor: 'black',
                  // fontSize:"2.4vw",
                  textAlign: "center",
                  padding: 16,
                  paddingTop: 80,
                  color: "white",
                  width: '100%',
                  //   height:'auto',
               }}
            >
               <div
                  style={{

                     borderStyle: 'solid',
                     height: 'auto',
                     borderRadius: 10,
                     borderColor: 'white'
                  }}
               >

                  <h1 className="who-we-are-title">
                     WHAT WE DO
                  </h1>
                  <span className="who-we-are-desc">
                     Cardwell Chiropractic treats the body, mind, and spirit. We believe in treating each patient with a comprehensive strategy involving physical adjustments, education, nutritional support, and wellness planning. Cardwell Chiropractic has freed many Georgia residents from pain and debilitating medication. Our balance of holistic treatment and sound medical knowledge ensure that you are in good hands.
                  </span>
               </div>
            </div>

         }
         {/* <hr/> */}
         <div className="cards-container">
            <h1 >Our Specialized Treatments</h1>
            <Card
               image={image1} 
               title={"Chiropractic Adjustment"}
               desc="At Cardwell Clinic we focus on  realigning the spine to its original structure by utilizing mirror image adjustments. With the help of modern digital x-ray technology we are able know exactly what is occurring in our patients’ bodies."
            />
            <Card
                image={image6}
               title={"Masssage Therapy"}
               desc="Neuromuscular massage therapy is the most advanced therapy and perfectly compliments our natural approach to healing the body. Neuromuscular therapy is the precise and thorough treatment that focuses on the soft tissue in the body. This hands on technique serves many purposes and is supported by clinical research in the field. Neuromuscular therapy can treat the following: ischemia, myofascil trigger points, pain, postural abnormalities, nerve compression and many other ailments"
            />

            <Card
               image={image7}
               title={"Family Wellness"}
               desc="Our primary focus in the East Cobb are is to serve the entire community. We work on all patients from all walks of life. We treat newborns, seniors, pregnant women, people suffering from general neck and back pain, and accident victims. We believe in the power of a healthy community and believe that Cardwell Chiropractic Clinic should be the first choice start in East Cobb and Marietta." />

            <Card
               image={image3}
               // title={"Digital X Rays"}
               desc="At Cardwell we are equipped with the most modern X-ray units on the market. Our units use x-ray sensitive plates that directly capture the location being observed, all while using up to 90% less radiation than other machines. With the help of this technology we are able know exactly what is occurring in our patients’ bodies. This gives our doctors the ultimate advantage and allows for the proper treatment to prescribed." />

            <Card
               image={image4} 
               title={"Foot Orthotics"}
               desc="At Cardwell Clinic we offer the best in foot orthotics treatment available in East Cobb. Our foot orthotics treatments can help support the arches of the feet. These treatments are best if you suffer from foot pain, knee pain, and low back pain." />


            <Card
               image={image5}
               title={"Lumbar Decompression"}
               desc="Lumbar decompression primarily focusses on decompressing and stretching the low back region. This therapy can be beneficial for arthritic conditions, injured discs, pinched nerves and low back pain in general. "
            />



            <Card
               image={image8}
               title={"Cervical Decompression"}
               desc="Similar to low back stretch but focusses on stretching/ decompressing the neck. This therapy can be beneficial for arthritic condition, injured discs, pinched nerves, and neck pain in general. We have two forms available at our clinic. Vertical (seated) cervical traction and (horizontal) cervical traction where the patient is lying down.
               we dont need to put the first part" />

            <Card
               image={image2}
               title={"Spinal Physical Therapy"}
               desc="Our doctors are certified spinal physical therapists. Spinal physical therapy has a broad spectrum of modalities but the ones specific to the Cardwell Chiropractic Clinic are cold laser therapy, electro therapy and as micro current therapy, vibration plate therapy, joint mobilization, and health education. We also focus on functional movement patterns. We like to integrate a healthy diet with this as well as a workout routine or some kind of activity routine for the patients." />

         </div>
         <div style={{
            backgroundColor: 'black',
            color: 'white',
            width: '100%',
            padding: 15,
            borderTopStyle: 'solid',
            borderWidth: 1,
            paddingTop: 16,
            borderColor: 'white'
         }}>

            <span style={{ fontSize: 30 }}>
               WE SPECIALIZE IN TREATING:
            </span>
            <div
               className="row-spec"
            >


               <div
                  className="column-spec"
               >
                  <ul style={{ listStyle: 'disc' }}>

                     <li> Neck Pain</li>
                     <li>Headaches</li>
                     <li>Sports Injuries</li>
                     <li>Tennis Elbow</li>
                     <li>Shoulder and Arm pain/ Numbness</li>
                  </ul>
               </div>

               <div
                  className="column-spec"

               >
                  <ul style={{ listStyle: 'disc' }}>
                     <li> Low Back Pain</li>
                     <li> Disc Rehabilitation </li>
                     <li>  Sciatica</li>
                     <li>  Auto Accident Injuries  </li>
                     <li> Birth Trauma</li>
                  </ul>
               </div>
               <div
                  className="column-spec-s"

               >
                  <ul style={{ listStyle: 'disc' }}>

                     <li>Joint Pain</li>
                     <li>Achilles Tendonitis</li>
                     <li>Spinal Degeneration</li>
                     <li>Childhood Spinal Development</li>
                     <li>Scoliosis</li>
                     <li>Degenerative Arthritis</li>
                     <li>Plantar Fascitis</li>
                  </ul>
               </div>
               <div
                  className="column-spec-s"

               >
                  <ul style={{ listStyle: 'disc' }}>

                     <li>Fibromyalgia</li>
                     <li>Carpal Tunnel Syndrome</li>
                     <li> Whiplash</li>
                     <li> Stiff Neck</li>
                     <li> Frozen Shoulder</li>
                     <li> Hip Pain</li>
                     <li>  Pulled hamstrings</li>
                     <li>  Radiating Arm and Leg Pain</li>
                     <li> Sprained Ankles</li>
                  </ul>

               </div>
            </div>
            WE ARE PROUD TO EXTEND A 20% DISCOUNT TO ALL COMMUNITY SERVICE PERSONNEL, FORMER AND ACTIVE MILITARY, POLICE, AND FIREFIGHTERS

         </div>
      </div>
   );
}

export default Treatments;


