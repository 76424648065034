import React from 'react';
import logo from '../../imgs/cardwell-logo1.png'
import './navbar.css'
import { NavLink,withRouter } from "react-router-dom";
import phoneIcon from '../../imgs/phone-solid.svg'
import facebookIcon from '../../imgs/facebook-square-brands.svg'
import navIcon from '../../imgs/bars-solid.svg'

class  Navbar extends React.Component {

    state={
      isOpen:false,
      scrollPosition:0
    }

    componentDidMount(){
      window.addEventListener("scroll", this.handleScroll);

    }

    handleScroll=()=>{
      this.setState({ scrollPosition: window.scrollY });
    }

    myFunction=()=>{

          let x = document.getElementById("myTopnav");
          if (x.className === "topnav") {
            x.className += " responsive";
          } else {
            x.className = "topnav";
          }
          
    }
    closeNavbar=()=>{
      setTimeout(()=>window.scrollTo(0,1),500)

            // let x = document.getElementById("myTopnav");
      // x.className = "topnav";
}

    render(){
      // const doc = document.getElementById("mybookscript")
      // console.log(doc, "dddd")      
      // document.body.removeChild(doc)
      let cssNavClass=this.state.scrollPosition<=0?"topnav":"topnav topnav-moving"
     
      if(this.props.location.pathname=='/blog'){
         cssNavClass="topnav topnav-moving"
       }
       
   
    
      return (
    
      <div className={cssNavClass} id="myTopnav">
        <img 
             className="img-nav"
             src={logo} 
             onClick={()=>this.props.history.push('/')}
             style={{float:"left",margin:8,marginRight:50}}
             width="300px" alt="not found" 
        />
        
        <span style={{float:'right'}}>
        <NavLink exact  to=""></NavLink>

            <NavLink exact  to="/#" onClick={this.closeNavbar} activeClassName="active" className="clink">Home</NavLink>
            <NavLink exact  to="/about#" onClick={this.closeNavbar} activeClassName="active" className="clink">About</NavLink>
            <NavLink exact  to="/chiropractor_treatments_east_cobb#" onClick={this.closeNavbar}  activeClassName="active" className="clink">Treatments</NavLink>
            {/* <NavLink exact  to="/blog#" onClick={this.closeNavbar} activeClassName="active" className="clink">Blog</NavLink> */}
            <NavLink exact  to="/contact#" onClick={this.closeNavbar} activeClassName="active" className="clink">Contact</NavLink>
            <a exact  href="/patientIntakeForm#" onClick={this.closeNavbar} activeClassName="active" className="clink">Patient Intake Form</a>
            
            <a 
                href='tel:770-977-9200' 
           
            >
                <span style={{fontSize:25}}>
                  <img src={phoneIcon} style={{marginRight:8}}  width="25"/>
                  770-977-9200
                </span>
                {/* <i className="fa fa-phone" style={{fontSize:22}} >
                   770-977-9200
                </i> */}
            </a>
            <a 
                href='https://www.facebook.com/cardwellchiro/' 
                target="_blank"
           
            >
              
                  <img src={facebookIcon} style={{marginRight:8}}  width="25"/>
                 
           
                {/* <i className="fab fa-facebook-f" style={{fontSize:22,position:'relative',top:-3,left:-7,color:'black'}} ></i> */}
            </a>
        </span>

        <a href="#"
           className="icon" 
           onClick={this.myFunction}
        >
          {/* <i className="fa fa-bars"></i> */}
          <img src={navIcon} width="20" />
        </a>


      </div>
        );

    }
}

export default withRouter(Navbar);