import React, { useEffect } from 'react';
import Header from './components/header/Header'
import Review from './components/reviews/Review'
import './App.css'
import Testimonials from './components/Testimonials/Testimonials';
import mapIcon1 from './imgs/Cardwell-Chiropractic-East-Cobb-1.webp'
import mapIcon11 from './imgs/Cardwell-Chiropractic-East-Cobb-1.png'

import phoneIcon from './imgs/phone-solid1.svg'


function App(props) {
  let mapIcon = mapIcon1
  if (navigator.appVersion.indexOf("Mac") != -1) {

    mapIcon = mapIcon11

  }


  return (

    <div className="drwer">
      <Header />
      <div
        className="font-home-black-parralex-box home-black-parralex-box"

      >
        <div className="text-home1">
          Cardwell Chiropractic Clinic has been proudly serving the East Cobb & Marietta area since 1980. We thrive to provide our patients with the highest quality service available. Whether you need to be treated for an injury, auto accident or just an on-going issue, the Cardwell team  is equipped with the latest digital X-ray technology that allows us to diagnose and treat your condition with pin point precision. Call now and find out how we can help you and your family!
        </div>

        <div className="mycontainer-home">
          <div style={{ height: '10vw' }}></div>

          <div style={{ float: 'right', width: '50%', paddingTop: 10 }}>
            <a
              href='tel:770-977-9200'

            >
              {/* <i className="fa fa-phone" style={{fontSize:70,color:'#2b71bc',marginBottom:"2.5%"}} ></i> */}
              <img src={phoneIcon} width="60" />
            </a><br />
            <span style={{ color: 'white', marginLeft: 20, fontWeight: 'bold' }}>CALL NOW</span>
          </div>


          <div style={{ height: '30vw' }}></div>
        </div>
      </div>


      <div style={{width: '100%', backgroundColor: '#FFFFFF80', display: 'flex', justifyContent: 'center'}}>
        <Testimonials />
      </div>
     


      <Review />

    </div>
  );
}

export default App;
