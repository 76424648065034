import React from 'react';
import './contact.css'

class  Form extends React.Component {
   state={
      name:'',
      phone:'',
      message:'',
      email:'',
      service:''
   }

   handleChange=(e)=>{
      this.setState({[e.target.name]:e.target.value})
   }

   handleSubmit=(e)=>{
     e.preventDefault()
   }

   render(){
    return (
      <div id="myForm" style={{width:"60%",margin:'auto',padding:"32px 0px 32px 0px"}}>
           <form  action="https://formspree.io/cardwellchiropractic@gmail.com" method="POST">
           <a name="Contact"></a>

               <input 
                     type="text"
                     id="fname"
                     name="name"
                     style={{
                        fontWeight:'bold',
                        color:'white',
                        fontSize:22,
                        backgroundColor:this.props.backgroundColor,
                        border: 'none',
                        borderBottom:'1px solid white'}}

                     value={this.state.name}
                     onChange={(e)=>this.handleChange(e)}
                      placeholder="Name"
               />

               <input 
                style={{
                  fontWeight:'bold',
                  fontSize:22,
                  color:'white',
                  backgroundColor:this.props.backgroundColor,
                  border: 'none',
                  borderBottom:'1px solid white'}}
                     type="phone"
                      id="fphone"
                      name="phone"
                     value={this.state.phone}
                     onChange={(e)=>this.handleChange(e)}
                     placeholder="Phone"
              />

               <input
                style={{
                  fontWeight:'bold',
                  fontSize:22,
                   color:'white',
                  backgroundColor:this.props.backgroundColor,
                  border: 'none',
                  borderBottom:'1px solid white'}}
                     type="email"
                     id="femail"
                     name="email"
                     value={this.state.email}
                     onChange={(e)=>this.handleChange(e)}
                     placeholder="E-mail"/>
               
               <textarea 
                style={{
                  fontWeight:'bold',
                  fontSize:22,
                  height:150,
                  color:'white',
                 backgroundColor:this.props.backgroundColor,
                 border: 'none',
                 borderBottom:'1px solid white'}}

                       id="message"
                       name="message"
                       placeholder="Message"
                       value={this.state.message}
                       onChange={(e)=>this.handleChange(e)}
                     
                >
               </textarea>
               <input type="submit" value="Send"
               
                     style={{
                        fontWeight:'bold',
                     fontSize:22,
                     backgroundColor:'#fdd700',
                     color:'white',
                     borderStyle:'soild',
                     borderRadius:20,
                     
                     }}
               />
           </form>

      </div>
   
    );
   }
}

export default Form
