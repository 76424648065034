import React, { Suspense } from 'react';
// import React from 'react';
import ReactDOM from 'react-dom';
// import  LazyLoad from 'react-lazyload';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Link } from "react-router-dom";
import './index.css'

import Navbar from './components/navbar/Navbar'
import Treatments from './views/Treatments/Treatments'
import About from './views/About/About'
import Contact from './views/Contact/Contact'
import Blog from './views/Blog/Blog'
import TreatmentDetails from './views/Treatments/TreatmentDetails'
import BookAnAppointment from './views/BookAppointment/BookAppointment'
import BlogDetails from './views/Blog/BlogDetails'
import App from './App'
import Arrow from './components/arrow/Arrow';
import PatientIntakeForm from './views/PatientIntakeForm/PatientIntakeForm'
// const Treatments = React.lazy(() => import('./views/Treatments/Treatments'));
// const About = React.lazy(() => import('./views/About/About'));
// const Contact = React.lazy(() => import('./views/Contact/Contact'));
// const Blog = React.lazy(() => import('./views/Blog/Blog'));

// const TreatmentDetails = React.lazy(() => import('./views/Treatments/TreatmentDetails'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
// const BlogDetails = React.lazy(() => import('./views/Blog/BlogDetails'));
// const App = React.lazy(() => import('./App'));



ReactDOM.render(
    <Suspense fallback={<div>Webstie is loading</div>}>
        <BrowserRouter>
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            {/* <Arrow/> */}

            <Navbar />

            <Route exact path='/' component={App}></Route>
            <Route exact path='/about' component={About}></Route>
            <Route exact path='/chiropractor_treatments_east_cobb' component={Treatments}></Route>
            <Route exact path='/contact' component={Contact}></Route>
            <Route exact path='/book' component={BookAnAppointment}></Route>
            <Route exact path='/blog' component={Blog}></Route>
            <Route exact path='/blogDetails' component={BlogDetails}></Route>
            <Route exact path='/blogDetails' component={BlogDetails}></Route>
            <Route exact path='/patientIntakeForm' component={PatientIntakeForm}></Route>

            <Route exact path='/TreatmentDetails/:id' component={TreatmentDetails}></Route>

            <Footer  />

        </BrowserRouter>
    </Suspense>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
