import React, {useEffect} from 'react';
import './patientIntakeForm.css'


function PatientIntakeForm() {
  setTimeout(()=>{
    const script = document.createElement('script');
    script.src = "https://cdn.reviewwave.com/js/reviewwave.js";
    script.charset = "utf-8";
    script.setAttribute('data-id', '894886218c737f42429eaf7b9c955d9556d0');
    script.setAttribute('id', 'mybookscript');
    const myscript = document.getElementById("myscript")
    myscript.appendChild(script);
  }, 100)
   return (
        <div className="book-background">
       
         <div id="myscript"></div>
            
        </div>
  );
}

export default PatientIntakeForm;


