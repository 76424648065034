import React from 'react';
import Slider from '../Slider';
import '../Slider/css/horizontal.css';
import './header.css'
import slider1 from './../../imgs/dr Feidy Chiropractor East Cobb x rays.webp'
import slider2 from './../../imgs/Dr Natalia Massanet Chiropractor East Cobb spanish.jpg'
import slider3 from './../../imgs/Massage_Therapy_East_Cobb.webp'
import slider4 from './../../imgs/Auto-Accident-Injury-East-Cobb.webp'

import slider11 from './../../imgs/dr Feidy Chiropractor East Cobb x rays.jpg'
import slider22 from './../../imgs/Dr Natalia Massanet Chiropractor East Cobb spanish.jpg'
import slider33 from './../../imgs/Massage_Therapy_East_Cobb.jpg'
import slider44 from './../../imgs/Auto-Accident-Injury-East-Cobb.jpg'

import { withRouter } from 'react-router-dom'

function myStyle(item) {

	if (item.scheduel == "PROGRAMAR AHORA" && window.innerWidth <= 1000) {
		return { background: `url('${item.image}') no-repeat center center` }

	}

	if (item.z && window.innerWidth >= window.innerHeight) {
		//  alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center` }

	}
	if (item.z && window.innerWidth <= 400) {
		// alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: -500 }

	}
	else if (item.z && window.innerWidth <= 500) {
		//  alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: '70%' }

	}
	else if (item.z && window.innerWidth <= 600) {
		// alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: -400 }

	}
	else if (item.z && window.innerWidth <= 700) {
		// alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: -300 }

	}
	else if (item.z && window.innerWidth <= 800) {
		// alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: 0 }

	}
	else if (item.z && window.innerWidth <= 900) {

		// 	alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center`, backgroundPositionX: -250 }

	}
	else if (item.z && window.innerWidth <= 1000) {
		// 	alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center` }

	}

	else {
		// 	alert(window.innerWidth)
		return { background: `url('${item.image}') no-repeat center center` }

	}

}

function Header(props) {
	let slideOneAfterFiltering = slider1
	let slideTwoAfterFiltering = slider2
	let slideThreeAfterFiltering = slider3
	let slideFourAfterFiltering = slider4

	// var Name = "Not known"; 
	// if (navigator.appVersion.indexOf("Win") != -1) Name =  
	//   "Windows OS"; 
	if (navigator.appVersion.indexOf("Mac") != -1) {

		slideOneAfterFiltering = slider11
		slideTwoAfterFiltering = slider22
		slideThreeAfterFiltering = slider33
		slideFourAfterFiltering = slider44
	}

	const content = [
		{
			title: 'Find Relief Now With Your East Cobb Chiropractor',
			description:
				'Do You Suffer from Headaches, Neck & Back Pain, or Sciatica? If So, Schedule an Appointment Now & Claim Your New Patient Special Today!',
			button: 'Read More',
			image: slideOneAfterFiltering,
			scheduel: 'Schedule Now',
			contactUs: 'Contact Us',
			user: 'Luan Gjokaj',
			z: true
		},
		{
			title: 'Neuromuscular Massage Therapy of East Cobb',
			description:
				'Schedule Now and Get 25% off Your First Visit!',
			scheduel: 'Schedule Now',
			contactUs: 'Contact Us',
			image: slideThreeAfterFiltering,
			user: 'Erich Behrens',
			z: true
		},
		{
			title: 'Best in Auto Accident Injury Treatment in East Cobb & Marietta',
			description:
				'Have You Been in an Auto Accident? Do You Need Treatment for an Injury? Contact Us Now & Let Our Team of Dedicated Professionals Develop a Wellness Plan for You!',
			scheduel: 'Schedule Now',
			contactUs: 'Contact Us',
			image: slideFourAfterFiltering,
			user: 'Erich Behrens',
		},
	];
	return (
		<Slider
			touchDisabled
			autoplay='4000'
			className="slider-wrapper">
			{content.map((item, index) => (

				<div
					key={index}
					className="slider-content"
					style={{ ...myStyle(item), backgroundColor: 'black' }}
				>
					<div className="inner" style={(window.innerWidth > window.innerHeight) && (window.innerWidth > 700) ? { top: '45%' } : {}}>
						<h1>{item.title}</h1>
						<p>{item.description}</p>
						<a href="/book">
							<button style={{ textTransform: "uppercase", fontSize: 18 }}>{item.scheduel}</button>
						</a><br />

					</div>
					<section>

					</section>
				</div>

			))}
		</Slider>
	);
}

export default withRouter(Header);
