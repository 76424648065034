export  const primaryColor='#3c6dae'

 export  const PARALEX_OVERLAY_COLOR='rgba(0,0,0,0.1)'
export  const OVERLAY_COLOR='rgba(0,0,0,0)'

export  const OVERLAY_COLOR_DEGRE_1='rgba(0,0,0,0.1)'
export  const OVERLAY_COLOR_DEGRE_2='rgba(0,0,0,0.2)'
export  const OVERLAY_COLOR_DEGRE_3='rgba(0,0,0,0.3)'
export  const OVERLAY_COLOR_DEGRE_4='rgba(0,0,0,0.4)'
export  const OVERLAY_COLOR_DEGRE_5='rgba(0,0,0,1.9)'

