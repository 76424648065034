import React from 'react';
import './contact.css'
import Navbar from '../../components/navbar/Navbar'
import Form from '../../components/Contact/Form';
import GoogleMap from '../../components/GoogleMap/GoogleMap'
import LazyLoad from 'react-lazyload';

function Contact() {

  return (
    <div className="contact-background">
      <a name="Contact"></a>

      <Navbar />

      <h1
        style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: 60 }}
      >
        Contact Us Today
      </h1>
      <Form backgroundColor={'rgba(0,0,0,0.0)'} />

      <LazyLoad offset={600}>
        <div style={window.innerWidth < 1200 ? { position: 'absolute' } : {}}><GoogleMap /></div>
      </LazyLoad>
      <section id="Map" name="Map" />
    </div>
  );
}

export default Contact;


