import React, { useEffect, useState } from 'react';
import './testimonials.css'
import "react-alice-carousel/lib/alice-carousel.css";


function Testimonial() {
  const [value, setValue] = useState(true)
  setTimeout(() => {
    // Append the first script to set the window._rwREEl variable
    const script1 = document.createElement('script');
    script1.innerHTML = `/*<![CDATA[*/window._rwREEl=(window._rwREEl||[]).concat(JSON.parse('{"el_id":"402abed9-3e3c-4cab-8ec0-97ec8ef8ffef","key":"8948-e465-f2e5-d91a-628c"}'))/*]]>*/`;
    document.body.appendChild(script1);

    // Append the second script to load the data
    const script2 = document.createElement('script');
    script2.src = 'https://rw-embed-data.s3.amazonaws.com/8948-e465-f2e5-d91a-628c.js';
    script2.async = true;
    document.body.appendChild(script2);

    // Append the third script to load the review embed functionality
    const script3 = document.createElement('script');
    script3.src = 'https://cdn.reviewwave.com/js/reviews_embed.js';
    script3.async = true;
    document.body.appendChild(script3);
    setValue(false)
  }, 500);

  return (
      <div className='outer'>
        <div className="rw-reviews-wrap" data-id="402abed9-3e3c-4cab-8ec0-97ec8ef8ffef"></div>
        {value? <div style={{color: 'white', fontSize: 32, textAlign: 'center'}}>Loading reviews...</div>: ""}
      </div>

  );
}

export default Testimonial;


