import React, {useEffect} from 'react';
import './bookAppointment.css'
import Navbar from '../../components/navbar/Navbar'
import GoogleMap from '../../components/GoogleMap/GoogleMap'
import LazyLoad from 'react-lazyload';

function Contact() {
  setTimeout(()=>{
    const script = document.createElement('script');
    script.src = "https://cdn.reviewwave.com/js/reviewwave.js";
    script.charset = "utf-8";
    script.setAttribute('data-id', '89482bb48a2e16f443009d32989d128c39ba');
    script.setAttribute('id', 'mybookscript');
    const myscript = document.getElementById("myscript")
    myscript.appendChild(script);
  }, 100)
   return (
        <div className="book-background">
        <a name="Contact"></a>

          
          <h1
           style={{textAlign:'center',color:'white',fontWeight:'bold',fontSize:60}}
          >
            Contact Us Today
            </h1>
         <div id="myscript"></div>
            
        </div>
  );
}

export default Contact;


