    import React from 'react';
    import './googlemap.css'
    import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
   
    const style = {
      width: '100vw',
      height:'100vw'
      // marginTop:100,
      // marginLeft:'17%',
      // position:"absolute",
     
     }
     const styleDesktop = {
      width: '50vw',
      height:'40vw',
      
       marginTop:100,
       marginLeft:'25%',
      
     
     }
    
    function GoogleMap(props) {
       
       return (
            <Map 
            style={window.innerWidth<1000?style:styleDesktop}
            google={props.google} zoom={20}
            initialCenter={{
                lat: 33.980890 ,
                lng:-84.420740
              }}
            >
              <Marker onClick={props.onMarkerClick}
              name={'Current location'} />
              <InfoWindow onClose={props.onInfoWindowClose}>
              <div>
              <h1>{"this.state.selectedPlace.name"}</h1>
              </div>
              </InfoWindow>
          </Map>
   
      );
    }
    
    export default GoogleApiWrapper({
      apiKey: ("AIzaSyCPWdTsaQnBU1P9f6zGdQEGr6k0dkt0bFY")
    })(GoogleMap)    
    
    