import React from 'react';
// import './treatments.css'
// import logo from '../../imgs/header-photo-about.jpg'
// import Navbar from '../../components/navbar/Navbar'
import {withRouter} from 'react-router-dom'
// import image1 from '../../imgs/chiropractic adjustment.jpg'

function TreatmentDetails(props) {
   
   return (
     <div style={{marginTop:100}} >

        <div style={{marginLeft:32}}>
                <div> 
                    <img src={require(`../../imgs/${props.match.params.id}.jpg`)} width="350vw" alt="not found" />

                    <div className="treatment-details-card-text">
                          
                     
                        <div>
                            <h2>{props.match.params.id}</h2>
                             <div>Lorem Ipsum is simplyyyyyyyttttt amdasdsadsladmlskmdklamdslmadlk</div>                           

                        </div>
                   </div>
                   
                </div> 
               
        
           </div>
           <div style={{marginTop:32,padding:32}}>
                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                   
                  <div style={{marginTop:'8vw'}}>
                              <span style={{fontSize:25,marginRight:8}}> Share</span>
                              <i className="fab fa-facebook-f"  style={{fontSize:32,marginRight:16}}></i>
                              <i className="fab fa-instagram"  style={{fontSize:32,marginRight:16}}></i>
                              <i className="fab fa-twitter"  style={{fontSize:32,marginRight:16}}></i> 
                  </div>
           </div>
           
        </div>
  );
}

export default TreatmentDetails;
