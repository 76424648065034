import React from 'react';
 import './blog.css'
import { withRouter} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import logo from '../../imgs/Chiropractic adjustments East Cobb team.jpg'
import {WEBSITE_LINK} from '../../API'


function BlogCard(props) {
   
   return ( 
      
        <div className="blog-card"
            
             style={{marginBottom:32,
                    visibility:props.v?'hidden':'visible'
            }}
         >
            <LazyLoad offset={-50}>
                <div className="blog-card-container"> 
                    <img src={logo} width="300px" alt="not found"/>

                    <div className="blog-card-text">
                         <div>
                            <h2>{props.title}</h2>
                            <div>{props.desc}</div>
                            <a href="./blogDetails/#top">
                                 <button 
                                       style={{marginTop:10}}
                                       
                                       >
                              
                                    
                                    View Blog
                                 
                                 </button>
                            </a>

                            </div> 
                   </div>
                </div> 
                </LazyLoad>
        </div>
     
  );
}

export default withRouter(BlogCard);
