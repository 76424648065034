import React from 'react';
import Form  from './Form'
import './contact.css'

function Contact(props) {
  
  return (
        <div style={{paddingTop:10}}>
          <h1 className='contact-title'
           style={{textAlign:'center',color:'white',position:'relative',bottom:20,fontWeight:'bold'}}
          >
            Contact Us Today
            </h1>
            
            <Form backgroundColor='rgba(0,0,0,0)'/>
            
        </div>

 );
}

export default Contact
