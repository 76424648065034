import React from 'react';
import './doctorbio.css'
import LazyLoad from 'react-lazyload';
// import primaryColor from '../../config/primaryColor'
 import popup1 from '../../imgs/72260712_1126319087564702_8132743797311275008_n.jpg'
 import popup2 from '../../imgs/72601070_2499804893422603_5074893220484218880_n.jpg'
 import popup3 from '../../imgs/72418827_2819161454818055_2134881359255044096_n.jpg'
 import popup4 from '../../imgs/72747996_943596142691540_8595432403541950464_n.jpg'

 import Modal from 'react-modal';

class  DoctorBio extends React.Component {
   
    state={
        flag:false,
        modalIsOpen: false,
    }
    openModal=()=>{
        this.setState({modalIsOpen:true})
    }
    closeModal=()=>{
        this.setState({modalIsOpen:false})
    }
    render(){
        const customStyles = {
            content : {
              borderStyle:"none",
              backgroundColor:"rgba(0,0,0,0)",
           
              // top                   : '50%',
              // left                  : '50%',
              // right                 : 'auto',
              // bottom                : 'auto',
              // marginRight           : '-50%',
              // transform             : 'translate(-50%, -50%)'
            }
          };
    
       return (
           <div className="row-doctorbio" style={{backgroundColor:'black',textAlign:'center'}}>
                             <h1 
                                   className="dr-title is-dr-title-v" 
                                   style={{
                                          display:'inline',
                                           textDecoration:'underline'
                                         }}
                              >

                                {this.props.name}
                          
                             </h1>

                <div className={`${this.props.classNameC} column-doctorbio-${this.props.direction}`}>
                    
                <LazyLoad offset={-1}>
                        <div className="my-fixed-text-doctorbiosecond">
                            <span className="bio-text-doctorbiosecond">
                                  {this.props.blueBox}
                            </span>
                        </div>
                        </LazyLoad>
                </div>
                  <span>
                  <h1 
                                   className="dr-title is-dr-title-v1" 
                                   style={{
                                           textDecoration:'underline'
                                         }}
                              >

                                {this.props.name}
                          
                             </h1>
                    <p  className="dr-desc">
                
                    {this.props.desc}
                    </p>
                  </span>
                
      
                    {this.props.v?
                    <div style={{display:'flex',justifyContent:'center',
                               color:'white'
                               }}>
                        <a style={{
                            fontSize:22,
                            marginLeft:25,
                            marginBottom:25,
                            cursor:'pointer',
                            }} onClick={()=>this.openModal()}
                        >
                           See More...
                        </a> 
                    </div>
                           :'' }
                <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Add New Car Modal">
          
          <div style={{marginTop:30}} onClick={()=>this.closeModal()}>
                <img width="300px" style={{margin:30}} src={popup1}/>
                <img width="300px" style={{margin:30}} src={popup2}/>
                <img width="300px" style={{margin:30}} src={popup3}/>
                <img width="300px" style={{margin:30}} src={popup4}/>

          </div>
              
        </Modal>
           </div>
      );
    }
}

export default DoctorBio;
