import React from 'react';
import './treatments.css'
// import logo from '../../imgs/header-photo-about.jpg'
import {withRouter} from 'react-router-dom'
import LazyLoad from 'react-lazyload';

function Card(props) {
   
   return (
     <LazyLoad offset={-10}>
        <div 
            // className="treatment-text-card"
             style={{
               marginBottom:16,
               visibility:props.v?'hidden':'visible',
            }}
         >
            <hr/>
            <div className="row-t">
            

                <div 
                   className='column-t' 
                 > 
                    <img
                         src={props.image}
                         width="100%" alt="not found" 
                    />

                </div> 
                <div className="column-p">
                          <h1>{props.title}</h1>
                          <p>{props.desc}</p>
                         
                </div>
                <div className="column-p">
                  <a href="/contact/#Contact">
                           <button  
                                 style={{marginTop:"20%",width:'100%'}}
                                 //  onClick={()=>props.history.push('/contact/#Contact')}
                           >
                                    SCHEDULE NOW
                           </button>
                  </a>
                </div>
            </div>
        </div>
        </LazyLoad>
  );
}

export default withRouter(Card);
