import React from 'react';
import {withRouter} from 'react-router-dom'
import arrowIcon from '../../imgs/arrow-down-solid.svg'

class  Arrow extends React.Component{

    state={
      visibility:'visible'
    }
  render(){


   let {pathname}= this.props.location
 
   setInterval(()=>{
    let element=document.getElementById("arrow1")

    let rect=element.getBoundingClientRect();
     if(rect.top<=294){
          this.state.visibility=="visible" && this.setState({visibility:'hidden'})
        }else{
          this.state.visibility=="hidden" && this.setState({visibility:'visible'})

        }
                            
        },100)
      return(
        <div id="arrow1" style={{visibility:this.state.visibility,position:'absolute',bottom:10,right:"50%",zIndex:10}}>
        {/* <button className="button1"> */}
            <img src={
                  pathname=='/contact' || pathname=='/blog' || window.innerWidth<1200?"":arrowIcon} 

               width="20"/>
        {/* </button> */}
    </div>
  
   )
 
}
}
export default withRouter(Arrow);
